<template>
  <div class="specialty-rank">
    <div class="left">
      <el-collapse value="1" accordion>
        <el-collapse-item
          :title="item.CategoryName"
          v-for="(item, index) in specialtyList"
          :key="index"
          :name="String(index+1)">
          <el-popover
            v-for="(subItem, subIndex) in item.Children"
            :key="subItem.CategoryCode"
            width="400"
            trigger="click"
            :content="subItem.CategoryName"
            placement="right"
            @show="showPopover(subItem)"
          >
            <div
              class="box"
              v-loading="categoryNameLoading"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
            >
              <el-button
                @click="selectSpecialtyName(thirdItem)"
                v-for="(thirdItem, thirdIndex) in specialtyCategoryNameList"
                :key="thirdItem.SpecialtyCode"
                type="text"
              >
                {{ thirdItem.SpecialtyName }}
              </el-button>
              <el-alert v-if="specialtyCategoryNameList.length === 0" title="暂无数据" :closable="false"></el-alert>
            </div>
            <div class="title-popover" slot="reference">{{ subItem.CategoryName }}</div>
          </el-popover>
        </el-collapse-item>
      </el-collapse>
    </div>

    <div class="right">
      <div class="head">
        <condition-screen @screenParameters="screenParameters"></condition-screen>
      </div>

      <div v-show="mainData.Data && mainData.Data.length > 0" class="total"><span class="link" @click="openSpecialtyDetail">{{ query.SpecialtyName }}</span>专业开设院校排名，共 {{ mainData.Total }} 条数据</div>

      <div v-if="mainData.Data && mainData.Data.length > 0" class="main">
        <div class="item" v-for="(item, index) in mainData.Data" :key="index">
          <div :class="[
            'item-item',
            'item-rank',
            {'first': item.Rank === 1},
            {'second': item.Rank === 2},
            {'third': item.Rank === 3}]"
          >
            {{ item.Rank }}
          </div>
          <div class="item-item item-info">
            <img class="logo" :src="item.LogoUrl" alt="">
            <div class="info">
              <div class="info-name" @click="openDetail(item)">{{ item.SchoolName }}</div>
              <div class="info-item">{{ item.TagNames }}</div>
            </div>
          </div>
          <div class="item-item province">
            <div class="type">省份</div>
            <div class="value">{{ item.ProvinceName }}</div>
          </div>
          <div class="item-item category">
            <div class="type">类型</div>
            <div class="value">{{ item.CategoryNames }}</div>
          </div>
          <div class="item-item hot">
            <div class="type">热度</div>
            <div class="value">{{ item.Hits }}</div>
          </div>
          <div class="item-item score">
            <div class="type">综合指数</div>
            <div class="value">{{ item.Score }}</div>
          </div>
        </div>
      </div>
      <div v-else class="have-no-data">暂无数据……</div>
      <div class="pagination">
        <custom-pagination
          v-show="mainData.Total > 0"
          layout="sizes, jumper"
          :pageIndex="query.PageIndex"
          :pageSize="query.PageSize"
          :pageSizeList="[10, 20, 50, 100, 300]"
          :total="mainData.Total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        ></custom-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api/config'
// import {SpecialtyCategoryList, SpecialtyList, SpecialtyRankList} from '@/api/search_specialty'
import ConditionScreen from "@/views/tool/specialty-rank/local-components/ConditionScreen";

export default {
  components: {
    ConditionScreen
  },
  name: "index",
  data () {
    return {
      activeNames: '1',
      categoryNameLoading: false,
      specialtyList: [],
      specialtyCategoryNameList: [],
      mainData: {},
      query: {
        SpecialtyCode: '',
        SchoolProvinceIdList: [],
        SchoolCategoryIdList: [],
        SchoolTagList: [],
        SchoolName: '',
        SpecialtyName: '', // 显示当前排行榜的专业
        PageIndex: 1,
        PageSize: 10
      }
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    // 打开专业详情
    openSpecialtyDetail () {
      let routerUrl = this.$router.resolve({
        path: '/specialty-detail',
        query: {
          SpecialtyCode: this.query.SpecialtyCode
        }
      })
      window.open(routerUrl.href, '_blank')
     
    },
    //打开学校详情
    openDetail (item) {
      let routerUrl = this.$router.resolve({
        path: '/university-detail',
        query: {
          id: item.SchoolId
        }
      })
      window.open(routerUrl.href)
    },
    // 选择具体专业
    selectSpecialtyName (thirdItem) {
      this.query.SpecialtyCode = thirdItem.SpecialtyCode
      this.query.SpecialtyName = thirdItem.SpecialtyName
      this.query.PageIndex = 1
      this.getList()
    },
    // 专业分类展示面板
    async showPopover (subItem) {
      this.categoryNameLoading = true
      let temp = await API.Specialty.SpecialtyList({
        CategoryCode: subItem.CategoryCode,
        PageIndex: 1,
        PageSize: 999
      })
      this.specialtyCategoryNameList = temp.data.Data
      this.categoryNameLoading = false
      return temp
    },
    // 筛选头部，参数接收
    screenParameters (params, callback) {
      Object.assign(this.query, params)
      this.query.PageIndex = 1
      this.getList()
      callback()
    },
    // 获取初始数据
    initialize () {
      let loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0,0,0,0.7)'
      })
      API.Specialty.SpecialtyCategoryList().then(res => {
        this.specialtyList = res.data.CategoryListBK
        this.showPopover(this.specialtyList[0].Children[0]).then(temp => {
          this.query.SpecialtyCode = temp.data.Data[0].SpecialtyCode
          this.query.SpecialtyName = temp.data.Data[0].SpecialtyName
          loading.close()
          this.getList()
        })
      })
    },
    // 获取专业排名列表
    getList () {
      let loading = this.$loading({
        lock: true,
        text: 'loading...',
        spinner: 'el-icon-loading',
        background: 'rgba(0,0,0,0.7)'
      })
      API.Specialty.SpecialtyRankList(this.query).then(res => {
        this.mainData = res.data
        loading.close()
      })
    },
    // 翻页
    handleCurrentChange (currentPage) {
      this.query.PageIndex = currentPage
      this.getList()
    },
    // 一页显示条数变化
    handleSizeChange (params) {
      this.query.PageSize = params
      this.query.PageIndex = 1
      this.getList()
    }
  }
}
</script>

<style scoped lang="less">
.specialty-rank {
  padding: 20px;

  display: flex;

  .left {
    flex-shrink: 0;
    width: 300px;
    padding: 20px;
    margin-right: 20px;
    background-color: #fff;

    .title-popover {
      padding: 10px;
      border-top: 1px solid #eeeeee;
      cursor: pointer;
    }
  }

  .right {
    .head {

    }

    .total {
      margin-top: 20px;
      font-size: var(--text-font-size);
      color: rgba(var(--themecolor),1);
      .link {
        color: var(--color-red);
        text-decoration: underline;
        cursor: pointer;
        transition: color 0.1s linear;
        margin-right: 20px;
        &:hover {
          color: rgba(var(--themecolor),1);
        }
      }
    }

    .main {
      padding: 20px;
      margin: 20px 0;
      background-color: #fefefe;
      border-radius: 10px;

      .item {
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-item {

          text-align: center;

          .type {
            font-size: var(--text-font-size);
            color: var(--text-font-color);
          }

          .value {
            font-size: var(--text-font-size);
            color: var(--text-font-color);
          }
        }

        &-rank {
          flex-shrink: 0;
          width: 50px;
          font-size: 25px;
          font-weight: 700;
          color: var(--text-font-color);
        }

        .first {
          color: rgba(215, 32, 32, 0.8);
        }

        .second {
          color: var(--color-red);
        }

        .third {
          color: #f8cc3d;
        }

        &-info {
          display: flex;
          align-items: center;

          .logo {
            width: 60px;
            margin-right: 10px;
          }

          .info {
            flex-shrink: 0;
            width: 200px;

            &-name {
              font-size: var(--text-font-size);
              color: var(--text-font-color);
              cursor: pointer;
            }

            &-item {
              font-size: var(--text-font-size);
              color: var(--text-font-color);
            }
          }
        }

        .province {
          width: 100px;
        }

        .category, .hot, .score {
          width: 60px;
        }
      }
    }

    .have-no-data {
      padding: 60px;
      text-align: center;
      color: #a0a0a0;
    }
  }
}

.box { /*popover弹出框内容宽度*/
  //max-width: 200px;
}
</style>
<style lang="less">
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: rgba(var(--themecolor),0.75);
}
.el-pagination.is-background .el-pager li:not(.disabled):hover{
  color: rgba(var(--themecolor),0.75);
  background-color: rgba(var(--themecolor),0.2);
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover{
  color: #FFF;
  background-color: rgba(var(--themecolor),0.75);
}
.el-pagination.is-background .btn-next:hover, .el-pagination.is-background .btn-prev:hover, .el-pagination.is-background .el-pager li:hover{
  background-color: rgba(var(--themecolor),0.2);
}
.el-pagination__sizes .el-input .el-input__inner:hover{
  border-color: rgba(var(--themecolor),0.75);
}
.el-range-editor.is-active, .el-range-editor.is-active:hover, .el-select .el-input.is-focus .el-input__inner{
  border-color: rgba(var(--themecolor),0.75);
}
.el-select-dropdown__item.selected{
  color: rgba(var(--themecolor),1);
}
.el-select .el-input__inner:focus{
  border-color: rgba(var(--themecolor),0.75);
}
.el-pagination__editor.el-input .el-input__inner:focus{
  border-color: rgba(var(--themecolor),0.75);
}
.el-button--text,.el-button--text:focus, .el-button--text:hover{
  color: rgba(var(--themecolor),1);
}
</style>
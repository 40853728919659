<template>
  <div v-if="Object.keys(mainData).length > 0" class="screen-container">
    <div class="content">
      <div class="content-title">
        院校地区：
      </div>
      <div :class="['no-limit', {activate: query.SchoolProvinceIdList.length === 0}]"
           @click="clearSelected('province')">不限
      </div>
      <el-checkbox-group class="content-item" v-model="query.SchoolProvinceIdList" size="mini">
        <el-checkbox-button v-for="(item, index) in mainData.ProvinceList" :label="item.ID" :key="index">
          {{ item.Name }}
        </el-checkbox-button>
      </el-checkbox-group>
    </div>
    <div class="content">
      <div class="content-title">
        院校类别：
      </div>
      <div :class="['no-limit', {activate: query.SchoolCategoryIdList.length === 0}]"
           @click="clearSelected('category')">不限
      </div>
      <el-checkbox-group class="content-item" v-model="query.SchoolCategoryIdList" size="mini">
        <!--        <el-checkbox-button label="">不限</el-checkbox-button>-->
        <el-checkbox-button v-for="(item, index) in mainData.SchoolCategoryList" :label="item.ID" :key="index">
          {{ item.CategoryName }}
        </el-checkbox-button>
      </el-checkbox-group>
    </div>
    <!--    <div class="content">-->
    <!--      <div class="content-title">-->
    <!--        办学性质：-->
    <!--      </div>-->
    <!--      <el-radio-group class="content-item" v-model="query.SchoolNature" size="mini">-->
    <!--        <el-radio-button label="">不限</el-radio-button>-->
    <!--        <el-radio-button v-for="(item, index) in mainData.SchoolNatureList" :label="item.Value" :key="index">-->
    <!--          {{item.Name}}-->
    <!--        </el-radio-button>-->
    <!--      </el-radio-group>-->
    <!--    </div>-->
    <!--    <div class="content">-->
    <!--      <div class="content-title">-->
    <!--        办学层次：-->
    <!--      </div>-->
    <!--      <el-radio-group class="content-item" v-model="query.EducationLevel" size="mini">-->
    <!--        <el-radio-button label="">不限</el-radio-button>-->
    <!--        <el-radio-button v-for="(item, index) in mainData.educationLevel" :label="item.ID" :key="index">-->
    <!--          {{item.Name}}-->
    <!--        </el-radio-button>-->
    <!--      </el-radio-group>-->
    <!--    </div>-->
    <div class="content">
      <div class="content-title">
        特别属性：
      </div>
      <div :class="['no-limit', {activate: query.SchoolTagList.length === 0}]" @click="clearSelected('tag')">不限</div>
      <el-checkbox-group class="content-item" v-model="query.SchoolTagList" size="mini">
        <!--        <el-checkbox-button label="">不限</el-checkbox-button>-->
        <el-checkbox-button v-for="(item, index) in mainData.SchoolTagList" :label="item.Value" :key="index">
          {{ item.Name }}
        </el-checkbox-button>
      </el-checkbox-group>
    </div>
    <div class="content">
      <div class="content-title">
        院校名称：
      </div>
      <el-input v-model="query.SchoolName" placeholder="请输入院校名称" clearable></el-input>
    </div>
    <div class="submit">
      <el-button class="submit-button" @click="submitScreen" type="primary" plain size="small"
                 :loading="screenButtonLoading">{{ screenButtonLoading ? '搜索中...' : '确认筛选' }}
      </el-button>
    </div>
  </div>
</template>

<script>
import API from '@/api/config'
// import {BaseCondition} from '@/api/common'

export default {
  name: "ConditionScreen",
  props: {
    conditionConfig: Array
  },
  data () {
    return {
      mainData: {},
      screenButtonLoading: false,
      query: {
        SchoolProvinceIdList: [],
        SchoolCategoryIdList: [],
        SchoolTagList: [],
        SchoolName: ""
      },
      isNewExam: false,
      newExamActive: false,
      // ruleFormLs: {},
      // ruleForm: {
      //   ProvinceId: "",
      //   ProvinceIdName: "",
      //   Year: "",
      //   SubjectType: '1',
      //   SelectSubject: "物理",
      //   BatchId: "",
      //   BatchName: "",
      //   Score: "",
      // },
      // rules: {
      //   Year: [
      //     { required: true, message: "请选择查询年份", trigger: "change" },
      //   ],
      //   ProvinceId: [
      //     { required: true, message: "请选择招生地区", trigger: "change" },
      //   ],
      //   Score: [{ required: true, message: "请填写分数！", trigger: "blur" }],
      //   SubjectType: [
      //     { required: true, message: "请选择科类！", trigger: "change" },
      //   ],
      //   SelectSubject: [
      //     { required: true, message: "请选择科目！", trigger: "change" },
      //   ],
      // },
      BatchList: [],
      tagText: "",
    }
  },
  mounted () {
    this.initialize()
    this.$globalEventBus.$on('screenButtonLoading', data => {
      this.screenButtonLoading = data
    })
  },
  methods: {
    // 点击不限时，清空该类的其他已选项
    clearSelected (type) {
      switch (type) {
        case 'province':
          this.query.SchoolProvinceIdList = []
          break
        case 'category':
          this.query.SchoolCategoryIdList = []
          break
        case 'tag':
          this.query.SchoolTagList = []
      }
    },

    //提交筛选参数
    submitScreen () {
      this.screenButtonLoading = true
      this.$emit('screenParameters', this.query, () => {
        this.screenButtonLoading = false
      })
    },
    //初始化数据
    initialize () {
      this.getScreenList()
    },
    async getScreenList () {
      this.mainData = (await API.School.BaseCondition()).data
      // this.mainData.educationLevel = [
      //   {Name: '本科', ID: 1},
      //   {Name: '高职高专', ID: 0}
      // ]
    }
  }
}
</script>

<style scoped lang="less">
.screen-container {
  padding: 2rem;
  border: 1px solid var(--color-grey);
  background-color: #fff;

  .content {
    margin-bottom: 1rem;
    display: flex;

    &-title {
      flex-shrink: 0;
      width: 12%;
      font-size: var(--text-font-size);
      margin-right: 3rem;
    }

    .no-limit {
      box-sizing: border-box;
      width: 68px;
      height: 24px;
      padding: 4px 12px;
      margin-right: 10px;
      border: 1px solid #e3e3e3;
      color: #606266;
      border-radius: 16px;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
    }

    .activate {
      background-color: rgba(var(--themecolor),0.75);
      color: #ffffff;
    }

    &-item {
      width: 90%;

      ::v-deep .el-checkbox-button {
        margin: 0 1rem .5rem 0;

        .el-checkbox-button__inner {
          padding: .3rem 1.2rem;
          border: 1px solid var(--color-grey);
          border-radius: 1rem;
        }

        .el-checkbox-button__orig-checkbox:checked + .el-checkbox-button__inner {
          box-shadow: none;
        }

      }

      ::v-deep .el-checkbox-button.is-checked .el-checkbox-button__inner {
        box-shadow: none;
      }
    }
  }

  .submit {
    text-align: center;

    &-button {
      width: 6rem;
    }
  }
}
</style>
<style lang="less">
.screen-container .el-input__inner:focus{
  border-color: rgba(var(--themecolor),0.75);
}
.el-checkbox-button.is-checked .el-checkbox-button__inner{
  background-color: rgba(var(--themecolor),0.75);
}
.el-checkbox-button__inner:hover,.el-radio-button__inner:hover{
  color: rgba(var(--themecolor),1);
}
.el-button--primary.is-plain{
  border-color: rgba(var(--themecolor),0.75);
  color: rgba(var(--themecolor),1);
}
.el-button--primary.is-plain:focus, .el-button--primary.is-plain:hover{
  background: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
</style>